<template>
	<div class="loginbody">
		<div class="login">
			<div class="mylogin" align="center">
				<h1>外网招采平台登录</h1>
				<el-form :model="user" :rules="rules" ref="userForm" label-width="0px">
					<el-form-item label="" prop="account" style="margin-top:10px;">
						<el-row>
							<el-col :span='2'>
								<span class="el-icon-s-custom"></span>
							</el-col>
							<el-col :span='22'>
								<el-input class="inps" placeholder='账号' v-model="user.username">
								</el-input>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item label="" prop="passWord">
						<el-row>
							<el-col :span='2'>
								<span class="el-icon-lock"></span>
							</el-col>
							<el-col :span='22'>
								<el-input class="inps" type="password" placeholder='密码' v-model="user.password">
								</el-input>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item style="margin-top:55px;">
						<el-button type="primary" round class="submitBtn" @click="login">登录
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {setRoutes} from "@/router";
	
	export default {
	  name: "Login",
	  data() {
	    return {
	      user: {},
	      rules: {
	        username: [
	          { required: true, message: '请输入用户名', trigger: 'blur' },
	          { min: 3, max: 15, message: '长度在 3 到 5 个字符', trigger: 'blur' }
	        ],
	        password: [
	          { required: true, message: '请输入密码', trigger: 'blur' },
	          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
	        ],
	      }
	    }
	  },
	  methods: {
	    login() {
	      this.$refs['userForm'].validate((valid) => {
	        if (valid) {  // 表单校验合法
	          this.request.post("/user/login", this.user).then(res => {
				  console.log(res)
	            if(res.code === '200') {
	              localStorage.setItem("user", JSON.stringify(res.data))  // 存储用户信息到浏览器
	              localStorage.setItem("menus", JSON.stringify(res.data.menus))  // 存储用户信息到浏览器
	
	              // 动态设置当前用户的路由
	              setRoutes()
	              
				  this.$message.success("登录成功")
				  this.$router.push("/fornt")
	
	            } else {
	              this.$message.error(res.msg)
	            }
	          })
	        }
	      });
	    }
	  }
	}
</script>

<style>
	.loginbody {
		overflow: scroll;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.login {
		width: 100vw;
		padding: 0;
		margin: 0;
		height: 100vh;
		font-size: 16px;
		background-position: left top;
		background-color: #242645;
		color: #fff;
		font-family: "Source Sans Pro";
		position: relative;
		background-image: url('/static/images/background.jpg');
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.mylogin {
		width: 240px;
		height: 280px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		padding: 50px 40px 40px 40px;
		box-shadow: -15px 15px 15px rgba(6, 17, 47, 0.7);
		opacity: 1;
		background: linear-gradient(230deg,
				rgba(53, 57, 74, 0) 0%,
				rgb(0, 0, 0) 100%);
	}

	.inps input {
		border: none;
		color: #fff;
		background-color: transparent;
		font-size: 12px;
	}

	.submitBtn {
		background-color: transparent;
		color: #39f;
		width: 200px;
	}
</style>
